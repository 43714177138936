import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './style.module.css';
import Button from '../../core/Button';
import Card from '../Card';
import Search from '../Search';
import { useTranslation } from '../../../../contexts/LocaleContext';
import { useNavigate } from 'react-router-dom';
import { useRestriction } from '../../../../contexts/Restriction/RestrictionContext';
import Filters from '../Filters';

const ScreenHeader = ({
  className,
  filters,
  activeFilters,
  filtersSchema,
  setFilters,
  setActiveFilters,
  onCreate = () => {},
  onSearch = () => {},
}) => {
  const { t } = useTranslation();
  const { withRestrictions } = useRestriction();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const onSearchChange = (event) => setSearchQuery(event.target.value || '');
  useEffect(() => {
    const throttleTimer = setTimeout(() => onSearch(searchQuery), 500);
    return () => clearTimeout(throttleTimer);
  }, [searchQuery, onSearch]);

  return (
    <Card className={cn(styles.wrapper, className)}>
      <Filters
        className={cn(styles.filters)}
        filters={filters}
        activeFilters={activeFilters}
        filtersSchema={filtersSchema}
        setFilters={setFilters}
        setActiveFilters={setActiveFilters}
        isFiltersOpen={isFiltersOpen}
        setIsFiltersOpen={setIsFiltersOpen}
      />

      <div className={cn(styles.main)}>
        {!isFiltersOpen && (
          <Button
            className={cn(styles.addButton)}
            width={125}
            height={36}
            color="#5ED615"
            title={<>{t('Create New')}</>}
            onClick={onCreate}
          />
        )}
        <div className={cn(styles.line)} />
        <Search
          className={cn(styles.search)}
          width={168}
          height={36}
          fontSize={14}
          inputProps={{ onChange: onSearchChange }}
        />
      </div>
    </Card>
  );
};

export default ScreenHeader;
