import React, { useMemo } from 'react';
import cn from 'classnames';
import styles from './style.module.css';
import Member from '../Member';

const MemberGroup = ({
  className,
  members,
  size = 32,
  maxVisible = 3,
  ...props
}) => {
  const visibleMembers = useMemo(
    () => members.slice(0, maxVisible),
    [members, maxVisible]
  );
  const extraMembersCount = useMemo(
    () => members.length - maxVisible,
    [members, maxVisible]
  );

  return (
    <div className={cn(styles.wrapper, className)} {...props}>
      {visibleMembers.map((current) => (
        <Member key={current.id} member={current} size={size} />
      ))}
      {extraMembersCount > 0 && (
        <div className={cn(styles.extra)} style={{ width: size, height: size }}>
          +{extraMembersCount}
        </div>
      )}
    </div>
  );
};

export default MemberGroup;
