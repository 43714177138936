import React, { useState } from 'react';
import {
  createCorporateCompany,
  createFlow,
  deleteCorporateCompany,
  deleteFlow,
  deleteWorker,
  updateCorporateCompany,
  updateFlow,
} from '../../../../api/CompanyAccount/dataSetters';
import {
  getCompanyInfo,
  getMyRole,
  getFlows,
} from '../../../../api/CompanyAccount/dataGetters';

import {
  createDepartment,
  deleteDepartment,
  updateDepartment,
} from '../../../../api/Department/dataSetters';
import {
  getCompanyWorkers,
  getDepartments,
} from '../../../../api/Department/dataGetters';

import { getInvitesList } from '../../../../api/AccountInvites/dataGetters';
import {
  confirmInvite,
  rejectInvite,
} from '../../../../api/AccountInvites/dataSetters';

const defaultData = {
  create: async () => {},
  delete: async () => {},
  update: async () => {},
  get: async () => {},
  load: async () => {},
  isLoaded: false,
  data: {},
};

const defaultListData = {
  ...defaultData,
  data: [],
};
const shape = {
  company: defaultData,
  departments: defaultListData,
  workers: defaultListData,
  flows: defaultListData,
  role: defaultData,
  invites: defaultListData,
};

const useStateField = ({
  getter = () => {},
  creator = () => {},
  updater = () => {},
  deleter = () => {},
}) => {
  const [data, setData] = useState({ data: null, isLoaded: false });
  const [isLoading, setIsLoading] = useState(false);

  const get = async () => {
    const response = await getter();
    setData({ data: response || null, isLoaded: true });
  };

  const load = async () => {
    if (data.isLoaded || isLoading) return;
    setIsLoading(true);
    await get();
    setIsLoading(false);
  };

  const create = async (data) => {
    await creator(data);
    await get();
  };

  const update = async (data) => {
    await updater(data);
    await get();
  };

  const remove = async (id) => {
    await deleter(id);
    await get();
  };

  return { ...data, get, create, update, remove, load };
};

const CorporateContext = React.createContext(shape);

export const useCorporateContext = () => {
  const data = {
    company: useStateField({
      getter: getCompanyInfo,
      deleter: deleteCorporateCompany,
      updater: updateCorporateCompany,
      creator: createCorporateCompany,
    }),

    departments: useStateField({
      getter: getDepartments,
      deleter: async (id) => {
        await deleteDepartment(id);
        await data.flows.get();
      },
      creator: createDepartment,
      updater: updateDepartment,
    }),

    invites: useStateField({
      getter: getInvitesList,
      creator: confirmInvite,
      deleter: rejectInvite,
    }),

    workers: useStateField({
      getter: getCompanyWorkers,
      deleter: deleteWorker,
    }),
    flows: useStateField({
      getter: getFlows,
      updater: updateFlow,
      creator: createFlow,
      deleter: async (id) => {
        await deleteFlow(id);
        await data.departments.get();
      },
    }),
    role: useStateField({
      getter: getMyRole,
    }),
  };

  const fetchAll = () => {
    for (const field in data) {
      if (data[field]?.load) data[field].load();
    }
  };

  return { ...data, fetchAll };
};

export default CorporateContext;
