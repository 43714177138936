import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Table,
  useReactTable,
} from '@tanstack/react-table';
import cn from 'classnames';
import styles from './style.module.css';
import { useTranslation } from '../../../../../../../contexts/LocaleContext';
import moment from 'moment';
import TaskProgress from '../TaskProgress';
import TaskTag from '../TaskTag';
import Member from '../../../../../../common/parts/Member';
import CorporateContext from '../../../CorporateContext';
import { useNavigate } from 'react-router-dom';
import {
  ChevronBottomIcon,
  ChevronTopIcon,
  DeleteIcon,
  EditIcon,
} from '../../../../../../common/icons';
import DeleteTaskModal from '../DeleteTaskModal';
import MemberGroup from '../../../../../../common/parts/MemberGroup';
import { useMediaQuery } from 'react-responsive';

const sortByAssigned = (rowA, rowB, _columnId) => {
  const assignedA = rowA.original.assign_to_ids || [];
  const assignedB = rowB.original.assign_to_ids || [];
  return assignedB.length - assignedA.length;
};
const sortByDate = (field) => (rowA, rowB, _columnId) => {
  const dateA = moment(rowA.original[field]);
  const dateB = moment(rowB.original[field]);
  return dateB.diff(dateA);
};
const sortByStatus = (rowA, rowB, _columnId) => {
  const statusA = rowA.original.status;
  const statusB = rowB.original.status;
  const statusOrder = [0, 1, 2];
  return statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB);
};
const sortByType = (rowA, rowB, _columnId) => {
  const typeA = rowA.original.type;
  const typeB = rowB.original.type;
  const typeOrder = [2, 1, 0];
  return typeOrder.indexOf(typeA) - typeOrder.indexOf(typeB);
};

const TaskTable = ({ className, data: tasks }) => {
  const { t } = useTranslation();
  const { workers, departments } = useContext(CorporateContext);
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState([
    {
      id: 'type',
      desc: false,
    },
  ]);
  const [data, setData] = useState([]);
  const [lastTask, setLastTask] = useState(null);
  const deleteTaskModal = useRef(null);
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 1600px)',
  });

  const employees = useMemo(() => {
    if (!workers.data || !departments.data) return [];
    const owners =
      workers.data.filter((current) => current.department_id === 0) || [];
    const departamentsEmployees = departments.data.reduce(
      (accumulator, department) => [
        ...accumulator,
        ...department.employees_department.map((employee) => employee),
      ],
      owners
    );

    return [...owners, ...departamentsEmployees];
  }, [workers.data, departments.data]);

  useEffect(() => {
    if (!workers.isLoaded) workers.load();
  }, [workers.isLoaded]);

  useEffect(() => {
    if (!departments.isLoaded) departments.load();
  }, [departments.isLoaded]);

  console.log('[debug][TaskTable.js] employees:', employees, {
    workers,
    departments,
  });

  useEffect(() => {
    setData(tasks);
  }, [tasks]);

  const open = (id) => {
    navigate(`/dashboard/company-dashboard/tasks/`);
    setTimeout(() => {
      navigate(`/dashboard/company-dashboard/tasks/view/subtask/${id}`);
    });
  };
  const onRowClick = (row) => {
    open(row.original.task_id);
  };
  const onEdit = (task) => (event) => {
    event.stopPropagation();
    navigate(`/dashboard/company-dashboard/tasks/`);
    setTimeout(() => {
      navigate(
        `/dashboard/company-dashboard/tasks/edit/subtask/${task.task_id}`
      );
    });
  };
  const onDelete = (task) => (event) => {
    event.stopPropagation();
    setLastTask(task);
    deleteTaskModal.current.open();
  };
  const onDeleted = (task) => {
    setData(
      data.filter(
        (current) =>
          current.id !== task.id &&
          !(current.child_tasks || []).some((i) => i.id === task.id)
      )
    );
    setLastTask(null);
  };

  const ActionsColumn = ({ task }) => (
    <div className={cn(styles.tableBodyRowActions)}>
      <button
        className={cn(styles.tableBodyRowActionButton)}
        type="button"
        onClick={onEdit(task)}
      >
        <EditIcon className={cn(styles.tableBodyRowActionButtonIcon)} />
      </button>
      <button
        className={cn(styles.tableBodyRowActionButton)}
        type="button"
        onClick={onDelete(task)}
      >
        <DeleteIcon className={cn(styles.tableBodyRowActionButtonIcon)} />
      </button>
    </div>
  );

  const columns = React.useMemo(
    () => [
      {
        size: 80,
        header: t('Type'),
        sortingFn: sortByType,
        enableSorting: true,

        accessorKey: 'type',
        cell: ({ cell, row }) => {
          const types = [
            {
              label: t('Standart'),
              background: '#BCE2EC',
              color: '#1E9DBD',
            },
            {
              label: t('Urgent'),
              background: '#F4C0B9',
              color: '#D92D15',
            },
            {
              label: t('Recurrent'),
              background: '#D8BDD4',
              color: '#7B206F',
            },
          ];
          const type = types[row.original.type] || types[0];
          return <TaskTag {...type} />;
        },
      },
      {
        size: 80,
        header: t('Task ID'),
        accessorKey: 'task_id',
      },
      {
        size: 334,
        header: t('Name'),
        accessorKey: 'title',
      },
      {
        size: 100,
        header: t('Assigned'),
        accessorKey: 'assign_to_ids',
        sortingFn: sortByAssigned,
        cell: ({ cell, row }) => {
          const members = employees.filter((employee) =>
            (row.original?.assign_to_ids || []).includes(employee.id)
          );

          return (
            <MemberGroup
              size={24}
              members={members}
              maxVisible={isLargeScreen ? 3 : 2}
              className="assignedMembers"
            />
          );
        },
      },
      {
        size: 80,
        header: t('Status'),
        accessorKey: 'status',
        sortingFn: sortByStatus,
        cell: ({ cell, row }) => {
          const statuses = [
            {
              label: t('To do'),
              background: '#FDD7BA',
              color: '#F67716',
            },
            {
              label: t('In progress'),
              background: '#E8BAD5',
              color: '#B11873',
            },
            {
              label: t('Done'),
              background: '#E7E7E8',
              color: '#83878B',
            },
          ];
          const status = statuses[row.original.status] || statuses[0];
          return <TaskTag {...status} />;
        },
      },
      {
        size: 140,
        header: t('Progress'),
        cell: ({ cell, row }) => <TaskProgress task={row.original} />,
      },
      {
        size: 90,
        header: t('Start date'),
        accessorKey: 'start_date',
        sortingFn: sortByDate('start_date'),
        cell: ({ cell, row }) =>
          moment(row.original.start_date).format('DD.MM.YYYY'),
      },
      {
        size: 90,
        header: t('End date'),
        accessorKey: 'end_date',
        sortingFn: sortByDate('end_date'),
        cell: ({ cell, row }) =>
          moment(row.original.end_date).format('DD.MM.YYYY'),
      },
      {
        size: 120,
        maxSize: 120,
        header: t('Actions'),
        cell: ({ cell, row }) => <ActionsColumn task={row.original} />,
      },
    ],
    [open, employees, isLargeScreen, ActionsColumn]
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      // rowSelection,
    },
    enableSorting: true,
    // enableRowSelection: true,
    // onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
  });

  return (
    <>
      <DeleteTaskModal
        ref={deleteTaskModal}
        task={lastTask}
        onDeleted={onDeleted}
      />

      <div className={cn(styles.wrapper, className)}>
        <table className={cn(styles.table)}>
          <thead className={cn(styles.tableHead)}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr className={cn(styles.tableHeadRow)} key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      className={cn(styles.tableHeadHeading)}
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ width: header.getSize() }}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          className={
                            header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : ''
                          }
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: (
                              <ChevronTopIcon
                                className={cn(styles.tableHeadSortIcon)}
                              />
                            ),
                            desc: (
                              <ChevronBottomIcon
                                className={cn(styles.tableHeadSortIcon)}
                              />
                            ),
                          }[header.column.getIsSorted()] ?? null}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className={cn(styles.tableBody)}>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr
                  className={cn(styles.tableBodyRow)}
                  key={row.id}
                  onClick={() => onRowClick(row)}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        className={cn(styles.tableBodyData, {
                          [styles.tableBodyDataDone]: row.original.status === 2,
                        })}
                        key={cell.id}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default React.memo(TaskTable);
