import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import cn from 'classnames';
import { Text, TextArea } from 'informed';
import styles from './style.module.css';
import Row from 'react-bootstrap/Row';
import moment from 'moment';

const InputDate = ({
  textarea = false,
  placeholder,
  inputPlaceholder,
  required,
  initialValue,
  errorStyle,
  className,
  minHeight = 40,
  ...otherProps
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState('');
  const inputRef = useRef(null);

  const onFocus = () => setIsFocused(true);
  const onBlur = () => setIsFocused(false);
  const onChange = (event) => setValue(event.target.value);

  useEffect(() => {
    if (!initialValue || !inputRef?.current) return;
    inputRef.current.valueAsDate =
      typeof initialValue === 'string'
        ? moment(initialValue).toDate()
        : initialValue;
  }, [initialValue]);

  const attribute = useMemo(() => {
    return moment(
      value || initialValue || moment().format('YYYY-MM-DD'),
      'YYYY-MM-DD'
    ).format('DD.MM.YYYY');
  }, [initialValue, value]);

  return (
    <div className={cn(className)}>
      {placeholder && (
        <div
          className={styles.placeholder}
          style={{ color: '#000', minHeight: 0 }}
        >
          <span className={styles.holderPlace}>
            {placeholder}
            <span className={styles.holderPlaceRequired}>
              {required ? '*' : ''}
            </span>
          </span>
        </div>
      )}
      <Row className={styles.inputContainer}>
        <div
          className={cn(styles.container, errorStyle, {
            [styles.containerFocus]: isFocused,
          })}
        >
          <Text
            ref={inputRef}
            onFocus={onFocus}
            onBlur={onBlur}
            className={cn(styles.input, styles.inputDate)}
            autoComplete={'off'}
            style={{ minHeight, height: '100%' }}
            required={required}
            value={attribute}
            onChange={onChange}
            initialValue={initialValue}
            placeholder={inputPlaceholder}
            data-attribute={attribute}
            {...otherProps}
          />
        </div>
      </Row>
    </div>
  );
};

export default InputDate;
